@import 'cofe';

@mixin blanc-pages-h4() {
    font-size: em(18);
    font-weight: 300;
}
@mixin blanc-pages-h6() {
    font-size: em(14);
    font-weight: 300;
}
@mixin transition($what, $speed, $ease: ease) {
    -webkit-transition: $what $speed $ease;
    transition: $what $speed $ease;
}

@mixin blanc-pages-arrow-up($size, $color) {
    width: 0;
    height: 0;
    border: none;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
}

@mixin blanc-pages-arrow-down($size, $color) {
    width: 0;
    height: 0;
    border: none;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
}
// background grey line
@mixin background_line() {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyRpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIE1hY2ludG9zaCIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDozM0Y3M0I1MkY1RjgxMUUzODBGMUNFMDkyMzNFRkUwRiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDpBRjUzMzY4MEY5MUMxMUUzODBGMUNFMDkyMzNFRkUwRiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjMzRjczQjUwRjVGODExRTM4MEYxQ0UwOTIzM0VGRTBGIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjMzRjczQjUxRjVGODExRTM4MEYxQ0UwOTIzM0VGRTBGIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+FBDzjQAAAA9JREFUeNpiuHjxIkCAAQAE6gJ0rs+NYAAAAABJRU5ErkJggg==');
}

@mixin transition($what, $speed, $ease: ease) {
    transition: $what $speed $ease;
}

@mixin arrow-up($size, $color) {
    width: 0;
    height: 0;
    border: none;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
}

@mixin arrow-down($size, $color) {
    width: 0;
    height: 0;
    border: none;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
}

@mixin blanc-pages-menu-button() {
    display: block;
    float: left;
    margin: 0;
    padding: 0 0.77778em;
    background: $color-primary-dark;
    border: none;
    @include font;
    font-size: em(18);
    color: $color-white;
    height: em(40, 18);
    line-height: em(40, 18);
    @include transition(color, 0.3s);
    z-index: 1;
    outline: none;
    text-decoration: none !important;
    outline: none;
    &:hover {
        color: $color-primary;
        @include transition(color, 0.3s);
    }
}

@function em($pixels, $context: $em) {
    @return #{$pixels/$context}em;
}

.blanc-pages-menu-button {
    @include blanc-pages-menu-button;
}

.blanc-pages-menu:after,
.blanc-pages-edit-menu:after {
    background: $color-grey-light;
}

.blanc-pages-form form {
    margin: 0 auto;
    float: none;
}

.blanc-pages-form {
    h1 {
        border-bottom: 0.2em solid $color-primary;
    }
    .blanc-pages-form-button-row {
        border-top: 0.0625em solid $color-primary;
        border-bottom: 0.375em solid $color-primary;
        button {
            font-weight: 500 !important;
            font-size: 1.25rem !important;
        }
    }
}

.blanc-pages-menu,
.blanc-pages-edit-menu {
    background: $color-primary-dark;
    * {
        background: $color-primary-dark;
    }
    button:hover {
        color: white;
    }
    .blanc-pages-version-previous:before,
    .blanc-pages-version-next:before,
    .blanc-pages-add-new-page:before {
        background: $color-primary-dark;

        .blanc-pages-version-edit-toggle span {
            background-color: $color-grey;
        }
    }
    .blanc-pages-edit-toggle,
    .blanc-pages-version-edit-toggle,
    .blanc-pages-version-manager,
    .blanc-pages-publishing-template,
    .blanc-pages-publishing-toggle,
    .blanc-pages-admin-url,
    .blanc-pages-publishing-save {
        border-left: 1px solid $color-grey-light;
    }
}

@mixin blanc-pages-form-close() {
    display: block;
    background: none;
    border: none;
    color: $color-white;
    margin: 0.4em 0 0 0;
    @include blanc-pages-h4;
    float: right;
    &:hover {
        color: $color-primary;
    }
}

.blanc-pages-form-close {
    @include blanc-pages-form-close;
}

.blanc-pages-menu-button:hover,
.blanc-pages-version-previous:hover,
.blanc-pages-version-manager:hover,
.blanc-pages-version-next:hover,
.blanc-pages-version-edit-toggle:hover,
.blanc-pages-edit-toggle:hover,
.blanc-pages-properties-toggle:hover,
.blanc-pages-admin-url:hover,
.blanc-pages-publishing-template:hover,
.blanc-pages-publishing-save:hover,
.blanc-pages-publishing-toggle:hover,
.blanc-pages-add-new-page:hover,
.blanc-pages-button-back:hover,
.blanc-pages-button-next:hover,
.blanc-pages-pagination ul li a:hover,
.blanc-pages-pagination ul li span:hover,
.blanc-pages-discard-version:hover:before,
.blanc-pages-breadcrumbs a:hover,
.blanc-pages-page-actions a:hover,
.blanc-pages-page-tabs a:hover,
.blanc-pages-form .blanc-pages-form-close:hover,
.blanc-pages-form .blanc-pages-form-button-row .blanc-pages-form-button:hover,
.blanc-pages-form .blanc-pages-form-button-row .blanc-pages-form-delete:hover,
.blanc-pages-form table a,
.blanc-pages-form tbody a,
.blanc-pages-form em,
.blanc-pages-form table a,
.blanc-pages-form tbody a,
.submit-row input:hover,
.submit-row a:hover,
.close:hover,
a {
    color: $color-primary;
}

.blanc-pages-form input[type='text'],
.blanc-pages-form input[type='number'],
.blanc-pages-form input[type='email'],
.blanc-pages-form input[type='url'],
.blanc-pages-form input[type='password'],
.blanc-pages-form textarea,
.blanc-pages-form select select {
    background-color: $color-white;
}

.blanc-pages-page-actions a,
.blanc-pages-page-tabs a {
    background: $color-primary-dark !important;
    border-left: 1px solid $color-grey;
    border-right: 1px solid $color-grey;
    transition: text-shadow 0.3s;

    &:hover {
        color: $color-white;
        text-shadow: 0 0 0.1px $color-white;
    }
}

.blanc-pages-form-button:hover {
    color: $color-white !important;
    text-shadow: 0 0 0.1px $color-white;
}
.blanc-pages-edit-toggle {
    background: $color-primary-dark;
    border-left: 1px solid $color-grey;
    border-right: 0;
    transition: text-shadow 0.3s;
    &:hover {
        text-shadow: 0 0 0.1px $color-white;
        color: $color-white;
    }
}

.blanc-pages-breadcrumbs li.blanc-pages-active a,
.blanc-pages-breadcrumbs a {
    background: $color-primary-dark;
    transition: text-shadow 0.3s;
    &:hover {
        text-shadow: 0 0 0.1px $color-white;
        color: $color-white;
    }
    &:before {
        background: $color-primary-dark;
    }
}

.blanc-pages-breadcrumbs li:after {
    color: $color-primary;
}

.blanc-pages-active .blanc-pages-breadcrumbs li.blanc-pages-active a:before {
    border-bottom: 0.5rem solid $color-primary;
}

.blanc-pages-version-edit-toggle span:after,
.blanc-pages-form .blanc-pages-live {
    background-color: $color-primary;
}

// end pages bits...
////////////////////////////////////////////////////////////////////////////////

.weight-normal {
    font-weight: 400;
}

////////////////////////////////////////////////////////////////////////////////
// setup the page

html,
body {
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Open Sans', Arial, sans-serif, sans-serif;
    font-size: 100%;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    &.back {
        background-color: $color-primary-dark;
    }
    .blanc-pages-context-menu {
        background: none;
    }
}

a {
    text-decoration: none;
    @include transition(color, 0.3s);
    &:hover {
        text-decoration: none;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
    font-family: 'Cabin', Arial, sans-serif;
}

h1,
.h1 {
    font-size: em(40);
}
h2,
.h2 {
    font-size: em(30);
    line-height: 1.167;
}
h3,
.h3 {
    font-size: em(20);
}
h4,
.h4 {
    font-size: em(18);
}
h5,
.h5 {
    font-size: em(16);
}
h6,
.h6 {
    font-size: em(14);
    font-weight: 600;
}

button,
input,
textarea,
select {
    @include font;
    -webkit-font-smoothing: antialiased;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
input[type='text'],
input[type='email'],
input[type='url'],
input[type='password'],
input[type='file'],
textarea {
    color: $color-text;
    background: $color-white;
}

button {
    @include transition(color, 0.3s);
}

////////////////////////////////////////////////////////////////////////////////
// header and menus

.header,
.footer {
    display: block;
    overflow: hidden;
    background: $color-primary-dark;
    min-height: 4em;

    a {
        color: $color-white;
    }

    h1 {
        float: left;
        font-size: em(40);
        font-weight: 600;
        line-height: 2;
        margin: 0;
        a {
            display: block;
            text-indent: 1.8em;
            transition: none;
            &:hover {
                color: $color-grey-light;
            }
            &:before {
                display: block;
                position: absolute;
                content: ' ';
                margin: 0.5em;
                width: 1em;
                height: 1em;
                background: url(../img/logo.png);
                background-size: cover;
            }
            @media only screen and (max-width: 1150px) {
                width: 1em;
                height: 1em;
                text-indent: 100em;
                overflow: hidden;
            }
            @media only screen and (max-width: 880px) {
                width: auto;
                height: auto;
                text-indent: 1.8em;
                overflow: auto;
            }
            @media only screen and (max-width: 420px) {
                width: 1em;
                height: 1em;
                text-indent: 100em;
                overflow: hidden;
            }
        }
    }

    small {
        display: block;
        clear: both;
        color: $color-white;
        border-top: 1px solid $color-primary;
    }
}

.ncistripes {
    display: block;
    height: 10px;
    background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="800" height="200" version="1"><g fill="none" fill-rule="nonzero"><path fill="#8FC2B8" d="M0 0h200v12H0z"/><path fill="#B0E1F1" d="M200 0h200v12H200z"/><path fill="#A690C5" d="M400 0h200v12H400z"/><path fill="#D37BA3" d="M600 0h200v12H600z"/></g></svg>');
    background-size: cover;
}

.blanc-pages-context-menu.blanc-pages-active {
    border-bottom: 1px solid $color-primary;
}

.menu-toggle,
.toggle,
.nav-toggle {
    display: none;
} // hide checkbox inputs

.toggle {
    @media only screen and (max-width: 880px) {
        display: block;
        position: relative;
        cursor: pointer;
        float: right;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        user-select: none;
        font-size: 2em;
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            margin-top: -0.3em;
            left: 50%;
            margin-left: -0.5em;
            width: 1em;
            height: 0.125em;
            border-top: 0.375em double $color-white;
            border-bottom: 0.125em solid $color-white;
        }
        &:after {
            content: ' ';
            display: block;
            width: 1.5em;
            height: 1.5em;
            margin: 0.5em;
            text-align: center;
        }
    }
}

.menu-toggle {
    @media only screen and (max-width: 880px) {
        &:checked ~ .menu {
            display: block;
            opacity: 1;
            margin: 0;
            border-top: 1px solid $color-white;
        }
    }
}

.nav-toggle {
    @media only screen and (max-width: 880px) {
        &:checked ~ .topmenu {
            display: block;
            opacity: 1;
            margin: 0;
            border-top: 1px solid $color-grey;
        }
    }
}

.menu,
.topmenu {
    float: right;
    margin: 0 0 0 2rem;
    padding: 1em 0;
    background: $color-primary-dark;
    &.context {
        float: none;
    }
    &.sub {
        overflow: hidden;
        display: none;
        border-top: 1px solid $color-primary;
        width: 100%;
        clear: both;
        @media all and (max-width: 880px) {
            border-top: none;
            li a {
                padding-left: 2em;
            }
        }
    }
    &.account {
        li {
            float: right;
        }
    }
    &.blanc-pages-active {
        display: block;
    }
    li {
        display: block;
        list-style: none;
        float: left;
        font-size: em(18);
        a {
            display: block;
            padding: 0 16px 0 16px;
            border-left: 1px solid $color-primary;
            line-height: 2.66666667em;
            transition: text-shadow 0.3s;
            &:hover,
            &.active {
                text-shadow: 0 0 0.1px $color-white;
                border-left: 2px solid $color-white;
                padding: 0 16px 0 15px;
                + a {
                    border-left: none;
                }
            }
            &:hover {
                &:after {
                    margin-bottom: -0.2em;
                    @include transition(margin, 0.3s);
                    .footer & {
                        margin-top: -0.2em;
                    }
                }
            }
            &.blanc-pages-active {
                border-left: 1px solid $color-grey;
                border-right: 1px solid $color-grey;
                border-bottom: 1px solid $color-primary-dark;
                background: $color-primary-dark;
                margin-right: -1px;
                margin-bottom: -2em;
                height: 2.95em;
                padding-bottom: 0.61111111em;
                z-index: 10;
                &:after {
                    margin-bottom: 0.5em;
                    @include arrow-up(0.3em, $color-white);
                }
                &:hover {
                    &:after {
                        margin-bottom: 0.75em;
                        @include transition(margin, 0.3s);
                    }
                }
                @media all and (max-width: 880px) {
                    border-left: 0;
                    margin-bottom: 0;
                    padding-bottom: 0;
                    height: auto;
                }
            }
        }
        &:first-child {
            border-left: none;
        }
    }
    @media only screen and (max-width: 880px) {
        display: none;
        width: 100%;
        background-color: $color-primary-dark;
        & > li {
            display: block;
            margin: 0;
            & > a {
                display: block;
                text-decoration: none;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
            }
        }
        & > li:first-child a {
            border-left: none;
        }
        &.context {
            display: block;
            position: relative;
        }
    }
    @media only screen and (max-width: 880px) {
        padding: 0;
        & > li {
            width: 100%;
            border: none;
            border-bottom: 1px solid $color-grey;
            & > a {
                border: none;
            }
        }
    }
}

.parent {
    & > a {
        position: relative;
        &:after {
            position: absolute;
            left: 50%;
            margin-left: -0.2em;
            bottom: 0;
            content: ' ';
            overflow: hidden;
            @include arrow-down(0.3em, $color-white);
            @include transition(margin, 0.3s);
        }
        &.active:after {
            color: $color-primary;
        }
        @media only screen and (max-width: 640px) {
            &:after {
                display: none;
            }
            .context &:after {
                display: block;
            }
        }
    }
}

.footer {
    small {
        overflow: hidden;
        padding: 1em 1.25em;
        border-top: 1px solid $color-primary;
        .legal {
            float: left;
            margin-right: 1em;
        }
        .author {
            float: right;
            a:hover {
                color: $color-white;
            }
            @media only screen and (max-width: 640px) {
                float: none;
                display: block;
            }
        }
    }
}

////////////////////////////////////////////////////////////////////////////////
// search form

.global-search {
    overflow: hidden;
    position: relative;
    height: em(60);
    form,
    input,
    button {
        display: block;
        background: $color-white;
        color: $color-text;
        margin: 0;
        padding: 0;
        border: none;
        @include font;
        appearance: none;
        outline: none;
    }
    form {
        margin: 0 em(90) 0 0; // 90px
    }
    input {
        float: left;
        font-size: em(30);
        font-weight: 300;
        width: 100%;
        height: 2em;
        padding-left: 0.5em;
        border-radius: 0;
    }
    button {
        float: right;
        font-size: em(18);
        height: em(60, 18);
        width: 5em; // 90px
        line-height: 2em;
        margin-right: -5em; // 90px
        &:hover {
            color: $color-primary;
            cursor: pointer;
        }
    }
}

////////////////////////////////////////////////////////////////////////////////
// content chunks in main

.blanc-pages-main {
    padding: 3em 2em;
    &.alt {
        border-bottom: 1px solid $color-primary;
        background: $color-primary-dark;
        color: $color-white;
        dl,
        ul {
            font-size: em(14, 16);
        }
        dt {
            position: absolute;
            float: left;
            display: block;
            line-height: 1;
            margin: 0.5em 0;
        }
        dd {
            margin: 0;
            border-bottom: 1px solid $color-grey;
            line-height: 1.5;
            padding: 0.25em 0 0.5em 3em;
        }
        a {
            color: $color-white !important;
            &:hover {
                color: $color-grey-light !important;
            }
        }
        .callactions a {
            color: $color-white !important;
        }
        p {
            font-size: em(14, 16);
        }
        ul.lined {
            li {
                border-bottom: 1px solid $color-primary;
            }
            strong {
                display: block;
            }
            a {
                display: inline;
                color: $color-grey-light !important;
                &:hover {
                    color: $color-white !important;
                }
            }
        }
        .right {
            text-align: right;
        }
    }

    table {
        width: 100%;
    }

    .blanc-pages-wrap {
        &.pushdown {
            margin-bottom: 1em;
        }
    }

    td {
        vertical-align: top;
    }

    .respond {
        display: block;
        width: 100%;
        height: auto;
    }

    .title {
        line-height: 60px;
        padding: 0;
        margin: 0;
        display: block;
        vertical-align: middle;
        overflow: hidden;
        .action {
            font-size: 18px;
            height: 60px;
            line-height: 60px;
            font-weight: normal;
            padding: 0;
            color: $color-text;
            &:hover {
                color: $color-primary;
            }
            &.right {
                float: right;
                margin-left: 1em;
            }
        }
    }
    h1.title {
        font-weight: 700;
    }
    hr {
        height: 0.333em;
        background-color: $color-primary;
        border: none;
        margin: 0;
    }

    dl {
        overflow: hidden;
    }

    dl.large {
        font-size: em(14, 16);
        line-height: em(27, 14);
        margin: 0.5em 0;
        display: block;
        overflow: hidden;
        dt {
            float: left;
            img {
                position: static !important;
            }
        }
        dd {
            display: block;
            overflow: hidden;
            font-size: em(18, 14);
            line-height: em(27, 18);
            margin-left: 6em;
            &.small {
                font-size: em(14, 18);
                margin-left: 9.8em;
                margin-bottom: 0.8em;
            }
            h2 {
                font-size: em(30, 18);
                margin-bottom: 0.1em;
                margin-top: em(0, 30) !important;
            }
            h6 {
                margin: 0;
                font-size: em(14, 18);
            }
            p {
                font-size: 14px;
            }
        }
        a {
            color: $color-text;
            &:hover {
                color: $color-primary;
            }
        }
    }
    .blanc-pages-thin dl.large {
        dd {
            margin-left: 5.5em;
            &.small {
                margin-left: 7.14em;
            }
        }
    }

    button:hover {
        color: $color-primary;
    }

    .mainimg {
        width: 160px;
        @media all and (max-width: 560px) {
            display: block;
            padding: 0;
        }
        img {
            margin-top: 0.5em;
        }
    }

    .maindetail {
        padding-left: em(20, 16);
        @media all and (max-width: 560px) {
            display: block;
            padding: 0;
        }
    }

    .details {
        margin: 1em 0;
        width: 100%;
        position: relative;
        &:after {
            display: block;
            content: '';
            margin-top: 1em;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -1em;
            border-bottom: 1px solid $color-grey-light;
        }

        .label {
            width: 7em;
            vertical-align: middle;
            height: em(40, 18);
        }
        h1 {
            font-weight: 600;
            margin: 0;
            line-height: 1;
        }
        td {
            vertical-align: middle;
        }
    }

    .threads {
        tr {
            border-bottom: 1px solid $color-grey-light;
        }
        .upvote {
            padding: 0;
            width: em(60, 16);
            vertical-align: top;
            .addvote {
                display: block;
                font-size: em(14, 16);
                font-weight: 600;
                &:before {
                    font-size: em(20, 14);
                    margin-top: em(20, 21);
                    display: block;
                    content: 'f';
                    @include icon;
                    color: $color-primary-dark;
                    background: $color-primary;
                    border-radius: em(29, 20);
                    width: em(29, 20);
                    height: em(29, 20);
                    line-height: em(29, 20);
                    @include transition(color, 0.3ms);
                    @include transition(background-color, 0.3ms);
                }
                &:hover:before {
                    background-color: $color-primary-dark;
                    color: $color-white;
                    @include transition(color, 0.3ms);
                    @include transition(background-color, 0.3ms);
                }
            }
            input[value='0'] + .addvote {
                &:before {
                    content: 'g';
                }
            }
        }
        .post {
            position: relative;
            padding-left: 100px;
            &.no-image {
                padding-left: 0;
            }
            h2 {
                margin: em(21, 30) 0 0 0;
                line-height: 1;
            }
            h3 {
                margin-top: 0;
            }
            h6 {
                margin: 0.5em 0;
            }
            p {
                @include blanc-pages-h6;
                font-weight: 400;
            }
            img {
                position: absolute;
                top: em(23, 16);
                left: 0;
            }
            h2 a,
            h3 a {
                color: $color-text;
                &:hover {
                    color: $color-primary;
                }
            }
        }
    }

    .callactions {
        &.toggle {
            display: none;
            &.blanc-pages-active {
                display: block;
            }
        }
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        border-bottom: em(6, 16) solid $color-primary;
        margin-bottom: 2em;
        li {
            text-align: right;
            border-top: 1px solid $color-grey-light;
            padding: 0.5em 0;
        }
        a {
            display: block;
            font-weight: 600;
            color: $color-text;
            &:hover {
                color: $color-primary;
            }
        }
        &.notop li:first-child {
            border-top: none;
        }
    }

    .lined {
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
        &.top {
            border-top: 1px solid $color-grey-light;
        }
        li {
            font-size: em(14, 16);
            padding: em(8.5, 16) 0;
            line-height: em(23, 16);
            border-bottom: 1px solid $color-primary;
            h4 {
                margin: 0;
                font-weight: 400;
                line-height: 1;
            }
            &.active {
                h4,
                a {
                    color: $color-primary;
                }
            }
            a {
                display: block;
                color: $color-text;
                &:hover {
                    color: $color-primary;
                }
            }
            &.checked {
                &:after {
                    @include icon;
                    display: block;
                    float: right;
                    content: 'k';
                    font-size: em(23, 18);
                }
            }
        }
        &.small li {
            font-size: em(14, 16);
            padding: em(8.5, 14) 0;
            line-height: em(23, 14);
            &.checked {
                padding-right: em(30, 14);
                &:after {
                    margin-right: em(-23, 14);
                }
            }
        }
        &.large li {
            font-size: em(18, 16);
            padding: em(8.5, 18) 0;
            line-height: em(23, 18);
            a {
                display: inline;
            }
            &.checked {
                padding-right: em(30, 18);
                &:after {
                    margin-right: em(-23, 18);
                }
            }
        }
        tbody {
            width: 100%;
        }
        tr {
            border-bottom: 1px solid $color-grey-light;
        }
        td {
            vertical-align: top;
            padding: 1.25em 0.625em;
            &.img {
                text-align: center; //width: 80px;
            }
            &.titlecell {
                font-size: em(18, 16);
                line-height: 1.3;
                padding-top: 1em;
                h4,
                a {
                    line-height: 1.3;
                }
                h6 {
                    margin: 0;
                    font-size: em(14, 18);
                    a {
                        color: $color-text;
                        &:hover {
                            color: $color-primary;
                        }
                    }
                }
                a {
                    color: $color-text;
                    &:hover {
                        color: $color-primary;
                    }
                }
            }
            &.dl {
                width: 40%;
                padding-right: 0;
            }
            &.right {
                text-align: right;
            }

            &.nopad {
                padding: 0.25em 0;
            }
            @media all and (max-width: 640px) {
                &.img,
                &.titlecell,
                &.dl {
                    display: block;
                    padding: 1.25em 0;
                }
                &.img {
                    float: left;
                    margin-right: 1em;
                }
                &.dl {
                    width: 95%;
                    clear: both;
                    padding-top: 0;
                }
            }
        }

        dl {
            font-size: em(14, 16);
            margin: 0;
            dt {
                float: left;
                line-height: em(17, 14);
            }
            dd {
                margin-left: 5em;
                line-height: em(17, 14);
                margin-bottom: 0.5em;
            }
            a {
                color: $color-primary;
            }
        }
        .title {
            font-size: em(18, 16);
            a {
                color: $color-primary-dark;
            }
        }
        a {
            color: $color-primary-dark;
            &:hover {
                color: $color-primary;
            }
        }
    }
    ul.lined {
        @media all and (max-width: 780px) {
            display: none;
        }
    }

    .bigiconwrap {
        padding: 4em 0;
        text-align: center;
        img {
            margin: 4em auto;
        }
    }

    .file-type-pdf,
    .file-type-audio,
    .file-type-video,
    .file-type-presentation,
    .file-type-document,
    .file-type-spreadsheet {
        display: block;
        margin: 0 auto;
        width: 80px;
        height: 80px;
        background: url(../img/file-pdf.png) center center no-repeat;
        background-size: 100%;
        &.bigiconwrap {
            width: 200px;
            height: 200px;
        }
    }

    .file-type-audio {
        background: url(../img/file-audio.png) center center no-repeat;
        background-size: 100%;
    }
    .file-type-video {
        background: url(../img/file-video.png) center center no-repeat;
        background-size: 100%;
    }
    .file-type-presentation {
        background: url(../img/file-presentation.png) center center no-repeat;
        background-size: 100%;
    }
    .file-type-document {
        background: url(../img/file-document.png) center center no-repeat;
        background-size: 100%;
    }
    .file-type-spreadsheet {
        background: url(../img/file-spreadsheet.png) center center no-repeat;
        background-size: 100%;
    }

    .lined-toggle:checked + .lined {
        display: block;
    }
    .lined-toggle:checked + .search-tab-panes .lined.active {
        display: block;
    }

    .tabs-toggle,
    .lined-toggle {
        display: none;
        -webkit-appearance: none;
        -moz-appearance: window;
        background-color: transparent;
        border: none;
        outline: none;
        @media all and (max-width: 780px) {
            display: block;
            float: none;
            font-size: 1em;
            width: 100%;
            position: static;
            &:before {
                display: block;
                content: 'Show menu';
                width: auto;
                position: static;
                margin: 0;
                height: auto;
                line-height: 1.5;
                float: none;
                font-size: em(18, 16);
                padding: 0.25em 0;
                border: none;
                border-bottom: 1px solid $color-grey-light;
            }
            &:after {
                display: none;
            }
        }
        &:checked:before {
            content: 'Hide menu';
        }
    }
    .tabs-toggle {
        &:before {
            content: 'Show filter';
        }
        &:checked:before {
            content: 'Hide filter';
        }
    }

    .tabs {
        margin: 0.625em 0 0 0;
        padding: 0;
        position: relative;
        list-style: none;
        overflow: hidden;
        @include background_line();
        background-position: bottom left;
        background-repeat: repeat-x;
        li {
            float: left;
            padding: 0.25em 0.7em;
            z-index: 10;
            margin-top: 1px;
            border-bottom: 1px solid $color-grey-light;
            &.active {
                margin-top: 0;
                background: $color-white;
                border: 1px solid $color-grey-light;
                border-bottom: 1px solid $color-white;
                a {
                    color: $color-grey-light;
                }
            }
            a {
                color: $color-text;
                &:hover {
                    color: $color-primary;
                }
            }

            h4 {
                margin: 0;
                font-weight: 400;
            }
        }
        @media all and (max-width: 780px) {
            display: none;
            margin: 0;
            li {
                float: none;
                display: block;
                padding-left: 0;
                padding-right: 0;
                &.active {
                    border: none;
                    border-bottom: 1px solid $color-grey-light;
                }
            }
        }
    }

    .tabs-toggle:checked + .tabs {
        display: block;
    }

    button {
        display: block;
        border: none;
        text-align: center;
        background-color: $color-white;
        color: $color-text;
        outline: none;
        &.right {
            float: right;
        }
    }

    .addbutton,
    .downloadbutton,
    .removebutton {
        display: block;
        border: none;
        border-bottom: 1px solid $color-grey-light;
        text-align: center;
        width: 100%;
        font-size: em(18, 16);
        height: em(60, 18);
        line-height: em(60, 18);
        padding: 0;
        background-color: $color-white;
        color: $color-text;
        &:after {
            position: absolute;
            @include icon;
            content: 'e';
            margin-left: 0.5em;
        }
        &:hover {
            color: $color-primary;
        }
    }
    .downloadbutton:after {
        content: 'n';
    }
    .removebutton:after {
        content: 'p';
    }

    .bigicon {
        display: block;
        border: none;
    }

    .blanc-pages-tab {
        background: $color-primary-dark !important;
    }

    .blanc-pages-pagination {
        overflow: hidden;
        margin-top: 0 !important;
        border-bottom: em(6, 16) solid $color-primary;
        .blanc-pages-button-next,
        .blanc-pages-button-back {
            float: right;
            color: $color-text;
            background: $color-white;
            &:hover {
                color: $color-primary;
            }
        }
        .blanc-pages-button-back {
            float: left;
        }
        ul {
            display: block;
            text-align: center;
            margin: 0;
            li {
                display: inline;
                a {
                    @include blanc-pages-menu-button;
                    color: $color-primary-dark;
                    background: $color-white;
                    float: none;
                    display: inline-block;
                    padding: 0 0.25em;
                    &.blanc-pages-active {
                        position: relative;
                        &:before {
                            content: ' ';
                            position: absolute;
                            @include blanc-pages-arrow-up(0.5rem, $color-primary);
                            left: 50%;
                            margin-left: -0.5em;
                            bottom: 0;
                            margin-bottom: -0em;
                        }
                        &:hover {
                            padding: 0 16px 0 16px;
                        }
                    }
                }
            }
        }
    }

    figure {
        margin: 2em 0;
        img {
            width: 100% !important;
            height: auto !important;
        }
        figcaption {
            color: $color-grey;
            font-size: 0.75em;
        }
    }
}

.default-profile-pic {
    float: left;
    overflow: hidden;
    width: em(80, 14);
    height: em(80, 14);
    background: $color-primary;
    line-height: 1.5;
    position: relative;
    &:after {
        position: absolute;
        display: block;
        width: 1em;
        height: 1em;
        text-align: center;
        @include icon;
        content: 'a';
        font-size: em(90, 14);
        margin-top: -0.15em;
        color: $color-white;
        margin-left: -0.04em;
    }
    &.large {
        width: em(140, 14);
        height: em(140, 14);
        &:after {
            font-size: em(160, 14);
        }
    }
}

#location_image {
    margin-top: 1em;
    position: relative;
    img {
        width: 100%;
        height: auto;
    }
}

#location_pin,
.location_pin {
    position: absolute;
    display: block;
    font-size: 2em;
    width: 1em;
    height: 1em;
    margin-left: -0.5em;
    margin-top: -1.2em;
    border-radius: 1em;
    background: $color-primary;
    &:before {
        display: block;
        position: absolute;
        font-size: 0.9em;
        @include icon;
        content: 'a';
        color: white;
        margin-top: -0.15em;
        margin-left: 0.07em;
        z-index: 10;
    }
    &:after {
        display: block;
        position: absolute;
        font-size: 1.8em;
        @include icon;
        content: 'g';
        color: $color-primary;
        margin-top: -0.2em;
        margin-left: -0.205em;
        z-index: 1;
    }
    &:hover {
        background: $color-primary-dark;
        &:after {
            color: $color-primary-dark;
        }
    }
}

.home-flex {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .home-flex__side {
        width: 100%;
        order: 2;
    }
    @media all and (min-width: 768px) {
        .home-flex__side {
            width: 35%;
            order: 1;
            &.spotlight {
                order: 3;
            }
        }
        .home-flex__main {
            width: 60%;
            order: 2;
        }
    }
    @media all and (min-width: 1110px) {
        .home-flex__side {
            width: 25%;
            &.spotlight {
                width: 25%;
            }
        }
        .home-flex__main {
            width: 44%;
        }
    }
}

.spotlight {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    h2,
    hr {
        width: 100%;
    }
    .spotlight__html {
        width: 100%;
        margin: 1.5em 0;
        a {
            display: none;
        }
        .smcx-embed {
            max-width: 100%;
            > .smcx-iframe-container {
                max-width: 100%;
            }
        }
    }
    .spotlight__twitter {
        width: 100%;
        ul.lined {
            display: block !important;
        }
        .row {
            margin: 1em 0;
            p {
                margin: 0.25em 0;
            }
        }
    }
    .spotlight__link {
        margin-bottom: 1.5em;
        width: 100%;
        .spotlight__link_image {
            display: block;
            width: 100%;
            height: 15em;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }
        .spotlight__link_text {
            display: block;
            width: 100%;
            padding: 0.5em;
            font-size: 1.25em;
            font-weight: 600;
            box-sizing: border-box;
            background: $color-primary;
            color: $color-white;
        }
        &:only-of-type {
            width: 100% !important;
            margin: 0 auto;
        }

        @media all and (min-width: 550px) {
            width: 48%;
        }

        @media all and (min-width: 1100px) {
            width: 100%;
        }
    }
    @media all and (min-width: 768px) {
        margin-top: 3.7em;
        width: 100% !important;
    }
    @media all and (min-width: 1100px) {
        display: block;
        width: 25% !important;
    }
}

.child-pages {
    padding-left: 0;
    margin-bottom: 3em;
}

////////////////////////////////////////////////////////////////////////////////
// blocks

.blanc-pages-text-block {
    h1,
    h3 {
        font-weight: 700;
    }

    p {
        font-size: em(18);
        font-weight: normal;
    }
}

.blanc_page_blocktype_pageblock {
    margin: 1em 0;
}

.row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.post {
    .postimg {
        float: left;
        margin: em(5, 16) em(20, 16) em(5, 16) 0;
    }
    h1 {
        font-weight: 600;
        margin-top: em(10, 40);
        line-height: em(45, 40);
    }
    .lined {
        width: auto;
        text-align: right;
        overflow: auto;
        border-top: 1px solid $color-grey-light;
        font-weight: 600;

        .addvote {
            float: left;
            font-size: 1em;
            font-weight: 600;
            padding: 0;
            &:before {
                float: left;
                font-size: em(14, 14);
                display: block;
                content: 'f';
                @include icon;
                margin-right: 0.5em;

                color: $color-text;
                background: $color-primary;
                border-radius: em(29, 20);
                width: em(29, 20);
                height: 2em (29, 20);
                line-height: em(29, 20);
                @include transition(color, 0.3ms);
                @include transition(background-color, 0.3ms);
            }
            &:hover:before {
                background-color: $color-primary-dark;
                color: $color-white;
                @include transition(color, 0.3ms);
                @include transition(background-color, 0.3ms);
            }
        }
        input[value='0'] + .addvote {
            &:before {
                content: 'g';
            }
        }
    }
    .callactions {
        .left {
            float: left;
            margin-right: 1em;
        }
    }
}

.comments {
    .blanc-pages-form {
        display: block;
        clear: both;
        padding: 0 1px;
        color: $color-primary-dark;
        textarea {
            border: 1px solid $color-grey-light;
        }
        p {
            margin-bottom: 0;
        }
        button {
            display: inline-block;
            padding-right: 0;
            font-weight: 600;
        }
        .callactions {
            overflow: hidden;
            margin-bottom: 0;
        }
    }
    dl {
        margin-bottom: 0 !important ;
        dt {
            position: absolute;
            padding-top: 1.5em;
        }
        dd {
            margin: 0 !important;
            padding-left: 100px;
            border-bottom: 1px solid $color-grey-light;
            padding-top: 1em;
            padding-bottom: 0.1em;
        }
    }
}

.handy-links,
.my-links {
    display: block;
    position: relative;
    &:after {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 1em;
        border-top: 1px solid $color-primary-dark;
    }
    @media all and (max-width: 640px) {
        &:after {
            display: none;
        }
    }
    .handy-link,
    .my-link {
        display: inline-block;
        width: 25%;
        position: relative;

        @media all and (min-width: 1200px) {
            width: 20%;
        }
        @media all and (max-width: 900px) {
            width: 33.3333%;
        }
        @media all and (max-width: 675px) {
            width: 50%;
        }
        @media all and (max-width: 480px) {
            width: 100%;
            a {
                border-right: none;
            }
        }
        a {
            border: none;
            border-right: 1px solid $color-primary;
            border-bottom: 1px solid $color-primary;
            overflow: hidden;
            height: 2.6em;
            &:hover {
                padding: 0 16px 0 14px;
            }
        }
        .handy-link-edit,
        .my-link-edit {
            position: absolute;
            right: 0;
            top: 0;
            display: block;
            border: none;
            padding: 0;
            height: 2.6em;
            overflow: hidden;
            &:before {
                display: block;
                @include icon;
                color: $color-white;
                content: 'z';
                height: 1.8em;
                line-height: 1.8;
                font-size: 1.5em;
            }
            &:hover {
                &:before {
                    color: $color-white;
                }
            }
        }
        .handy-link-add,
        .my-link-add {
            &:before {
                display: block;
                float: right;
                @include icon;
                color: $color-white;
                content: 'e';
                font-size: 1.5em;
                margin-right: -0.5em;
            }
            &:hover {
                &:before {
                    color: $color-white;
                }
            }
        }
    }
}

.meta-footer {
    clear: both;
    dl {
        font-size: em(14);
        line-height: 1.43;
        border-top: 0.4286em solid $color-primary;
        padding-top: em(20, 14);
        dt {
            float: left;
            display: block;
            width: 6em;
            margin: 0;
        }
        dd {
            margin-left: 6em;
        }
        & > dd:first-of-type {
            margin-bottom: 1em;
        }
    }
}

.blanc-pages-menu-person {
    display: none;
    &.blanc-pages-active {
        display: block;
    }
}

.close {
    @include blanc-pages-form-close;
    font-size: 0.6em;
}

#userthread-filter-form {
    #div_id_sorting {
        display: block;
        .asteriskField {
            display: none;
        }
        .control-label {
            display: block;
            font-size: em(30);
            line-height: 2em;
            font-weight: 300;
            border-bottom: em(6, 30) solid $color-grey-dark;
        }
        &:after {
            content: 'Filter';
            display: block;
            font-size: em(30);
            line-height: 2em;
            margin-top: 0.5em;
            font-weight: 300;
            border-bottom: em(6, 30) solid $color-grey-dark;
        }
        .radio {
            display: block;
            font-size: em(18);
            line-height: em(40, 18);
            border-top: 1px solid $color-grey-dark;
            &:first-child {
                border-top: none;
            }
            input {
                margin-right: 10px;
            }
        }
    }
    .widget_checkboxinput {
        display: block;
        font-size: em(18);
        line-height: em(40, 18);
        border-top: 1px solid $color-grey-dark;
        &:first-child {
            border-top: none;
        }
        input {
            margin-right: 10px;
        }
    }
}

.asteriskField {
    color: $color-primary;
}

.control-label {
    font-size: em(14);
    line-height: em(30, 14);
}

.blanc-pages-form .widget_select,
.widget_select {
    .controls {
        overflow: hidden;
        position: relative;
    }
}

html.ie {
    .blanc-pages-form select {
        background: white;
    }
}

.widget_checkboxinput {
    position: relative;
    .controls {
        display: block;
        overflow: hidden;
        font-size: em(18);
        line-height: em(40, 18);
        input {
            float: left;
            display: block;
            appearance: none;
            background: white;
            border: none;
            height: 20px;
            width: 20px;
            margin: 12px 10px 10px 0;
        }
    }
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
}
.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    max-height: 320px;
}

.equalheights.title {
    display: table-cell;
    vertical-align: bottom;
    line-height: em(20);
    padding: em(15, 30) 0;
}

// redactor bits

::selection,
.redactor_box ::selection {
    color: white !important;
}

.redactor_editor {
    border: none !important;
    color: $color-text;
    font-size: $em;
    div,
    p,
    ul,
    ol,
    table,
    dl,
    blockquote,
    pre,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include font;
    }
    div,
    p,
    ul,
    ol,
    table,
    dl,
    blockquote,
    pre {
        font-size: 16px;
        line-height: 24px;
    }
    hr {
        height: 6px;
        background-color: $color-primary;
        border: none;
        margin: 0;
    }
}

#redactor_modal {
    @include font;
    input[type='text'] {
        @include font;
        font-size: 16px;
        padding: 0 1em;
        text-indent: 0;
        display: block;
        width: 100%;
        height: 2.5em;
        line-height: 2.5;
        border-radius: 0;
    }
}

.redactor_dropdown a {
    color: $color-text !important;
    &:hover {
        color: $color-white !important ;
    }
}

#redactor_modal footer button.redactor_modal_action_btn,
.redactor_dropdown a:hover,
.redactor_toolbar li a:hover,
::selection,
.redactor_box ::selection {
    background: $color-primary;
}

#redactor_modal footer button.redactor_modal_action_btn:hover {
    color: white;
    background: $color-primary;
}

.comments .redactor_box {
    border: 1px solid $color-grey-light;
}

#redactor_modal_overlay,
#redactor_modal,
.redactor_dropdown {
    z-index: 1230 !important;
}

.medium-editor-element {
    background-color: white;
    color: black;
    min-height: 300px;
    padding: 10px;
    outline: none;
}

.medium-editor-element::placeholder {
    color: black;
}

.medium-editor-hidden {
    display: none !important;
}

.medium-editor-placeholder:after {
    color: #000;
}

#handbook-form .selectmultiple {
    min-height: 200px;
}

// Medium text and image upload overrides
.medium-insert-images-left.mediumInsert,
.medium-insert-images.medium-insert-images-left,
.mediumInsert.small {
    margin: 0 30px 0 0;
}

.medium-insert-images-right.mediumInsert,
.medium-insert-images.medium-insert-images-right {
    margin: 0 0 0 30px;
}

ul.lined {
    + .medium-insert-images-left.mediumInsert,
    + .medium-insert-images.medium-insert-images-left,
    + .mediumInsert.small,
    + .medium-insert-images-right.mediumInsert,
    + .medium-insert-images.medium-insert-images-right {
        margin-top: 1rem;
    }
}

.medium-insert-images-left.mediumInsert,
.medium-insert-images.medium-insert-images-left,
.mediumInsert.small,
.medium-insert-images-right.mediumInsert,
.medium-insert-images.medium-insert-images-right {
    figure {
        margin: 0.5rem 0 1rem 0;
    }
    max-width: 36%;
    @media all and (max-width: 420px) {
        max-width: 100%;
    }
    @media all and (max-width: 768px) {
        max-width: 50%;
    }
}

.medium-insert-images-grid.mediumInsert,
.medium-insert-images.medium-insert-images-grid {
    padding: 1rem 0;
    flex-wrap: nowrap;
    figure {
        margin: 0rem 0;
        flex: 1;
    }
}
