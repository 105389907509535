
@import 'normalize';

@mixin icon {
    font-family: 'HandbookGlyphsRegular', sans-serif;
    font-weight: normal;
}

@mixin font {
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 400;  // 400=normal, 300=light
}

$color-text: #1a1a1a;
$color-primary: #a690c5;
$color-primary-dark: #382e75;
$color-primary-light: darker(#382e75, 50%);
$color-secondary: #95317A !default;
$color-tertiary: #6C93CE !default;

$color-white: #fff;
$color-grey-light: #d1d1d1;
$color-grey: #A5A5A5 !default;

$color-grey-dark: #3f3f3f;


$em:   16;

.header, .footer {
    h1 {
        a {
            &:before {
                background: url(../img/logo_cofe.png);
                background-size: cover;
            }
        }
    }
}
